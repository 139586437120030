const firebaseConfig = {
  apiKey: "AIzaSyCQRgqdIzxp9E1M-eCISeq2RkkzWjiJHfs",
  authDomain: "pomo-timer-c8051.firebaseapp.com",
  projectId: "pomo-timer-c8051",
  storageBucket: "pomo-timer-c8051.appspot.com",
  messagingSenderId: "1083489663344",
  appId: "1:1083489663344:web:c7253c9a08c911a6cd77b5",
};

export default firebaseConfig;
