export const CHANGE_CURRENT = "change current";
export const START = "start";
export const PAUSE = "pause";
export const EDIT_COUNTER = "edit counter";
export const RESET = "reset";
export const CHANGE_SESSION = "change session";
export const TOGGLE_SETTING = "toggle setting";
export const REFRESH = "refresh";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const SET_CURRENT_BG = "set current bg";
export const SET_CURRENT_AUDIO = "set current audio";
export const SET_ALL_BG_DATA = "set all bg data";
export const SET_AUDIO = "set audio";
export const TOGGLE_AUDIO = "toogle audio";
export const ADD_TASK = "add task";
export const SET_TASKS = "set tasks";
export const REMOVE_TASK = "remove task";
export const TOGGLE_DRAWER = "toggle drawer";
export const TOGGLE_FOCUS = "toggle focus";
export const SET_ANALYTIC_DATA = "set analytic data";
export const ADD_TAG = "add tag";
export const GET_TAGS = "get tags";
